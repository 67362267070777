import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_a_muse_5.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="禾順商旅-徐舒涵 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "19疫情肆虐時，台北市到處在找尋願意提供警消醫護輪班休息的地方，而第一家願意公開表示支持的，正是位於梧州及廣州街口的禾順商旅，在本月剛正式成為台北第一家的警消醫護加油棧，經過當地商家的說明，才知到華西街大火時，受災的住戶現在也都被妥善的安置於此。",
            },
            {
              property: `og:title`,
              content: "禾順商旅-徐舒涵 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "19疫情肆虐時，台北市到處在找尋願意提供警消醫護輪班休息的地方，而第一家願意公開表示支持的，正是位於梧州及廣州街口的禾順商旅，在本月剛正式成為台北第一家的警消醫護加油棧，經過當地商家的說明，才知到華西街大火時，受災的住戶現在也都被妥善的安置於此。歐陽如修",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/project_a_muse_5-2.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;